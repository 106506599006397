.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.formMargins {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.title {
  composes: formMargins;
}

.description {
  composes: formMargins;
  flex-shrink: 0;
}

.description > textarea {
  padding-bottom: 4px;
  height: inherit !important;
  overflow: hidden !important;
  line-height: 32px;
}

.category {
  composes: formMargins;
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }

  @media only screen and (max-width: 768px) {
    margin-top: 0;
  }
}

.datesFieldsWrapper {
  composes: formMargins;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}

.availableUntil {
  margin-left: 40px;
}

.explanatoryText {
  color: #b3b3b3;
  line-height: 22px;
  margin-top: 0;
  font-size: 14px;
}

.checkboxGroup {
  margin-top: 20px;
}

.fieldLabel {
  font-weight: var(--fontWeightSemiBold);
  padding-top: 6px;
  padding-bottom: 2px;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 16px;
  font-size: 14px;
}

.counterWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  min-width: 200px;
  margin-bottom: 20px;
}

.counterIcon {
  cursor: pointer;

  &:hover {
    color: var(--marketplaceColor);
    transform: scale(1.05);
  }
}

.counterValue {
  margin: 5px;
}
