@import '../../styles/propertySets.css';

.root {
  @apply --marketplaceModalFormRootStyles;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.name {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.firstNameRoot {
  width: calc(34% - 9px);
}

.lastNameRoot {
  width: calc(66% - 9px);
}

.organizationName {
  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.organizationType {
  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.password {
  @apply --marketplaceModalPasswordMargins;
}

.bottomWrapper {
  @apply --marketplaceModalBottomWrapper;
  margin-top: 40px;
}

.bottomWrapperText {
  @apply --marketplaceModalBottomWrapperText;
  padding-left: 4px;
  padding-right: 4px;
}

.termsText {
  @apply --marketplaceModalHelperText;
  font-size: 14px;

}

.termsLink {
  @apply --marketplaceModalHelperLink;
  font-weight: 500;
  font-size: 14px;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.profileType {
  margin-bottom: 1pc;
}

.checkbox {
  margin-top: 40px;
}

.newsletter {
  font-weight: 500;
  font-size: 14px;
  text-align: start;
}

.newsletterFrom {
  font-size: 14px;
  font-weight: 300;
  color: #939393
}