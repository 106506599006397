.icon {
  height: 20px;
  width: auto;
  margin: 0 auto;
  cursor: pointer;

  @media (max-width: 768px) {
    margin: 0 10px;
  }
  &:hover {
    transform: scale(1.05);
  }
}

.langSwitchWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding-bottom: 1px;
  height: 100%;
  margin: 0 15px;
}

.langSwitchWrapperMobile {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 100%;
  margin: 0;
}

.labelWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11;
  height: 45px;
  width: 45px;

  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    &:hover {
      background-color: var(--marketplaceColorLight);
    }
  }
}

.profileMenuContentMobile {
  width: 52px !important;
  left: 0 !important;
}

.mobileLabel {
  display: none;

  @media (min-width: 768px) {
    display: none;
  }
}
